// Mixins
// --------------------------

@mixin fa-icon {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    vertical-align: -0.125em;
}

@mixin fa-icon-rotate($degrees, $rotation) {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
    transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation}, mirror=1)";
    transform: scale($horiz, $vert);
}

// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/

@mixin sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate

@mixin sr-only-focusable {
    &:active,
    &:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

@mixin animation($value) {
    -webkit-animation: unquote($value);
    -moz-animation: unquote($value);
    -o-animation: unquote($value);
    animation: unquote($value);
}

@mixin animation-property($property, $value) {
    -webkit-animation-#{$property}: unquote($value);
    -moz-animation-#{$property}: unquote($value);
    -o-animation-#{$property}: unquote($value);
    animation-#{$property}: unquote($value);
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}
