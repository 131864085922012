.faq {
    @extend .card;
    margin-bottom: 2rem;

    .faq-content {
        @extend .card-body;
    }

    .question {
        @extend .card-title;
    }

    .answer {
        @extend .card-text;
    }
}
