@import "mixins";

.image-block {
    margin-bottom: 2rem;

    figure {
        @extend .figure;
        display: block;
        margin: auto;

        padding: 0 12rem;
        @include media-breakpoint-down(lg) {
            padding: 0 6rem;
        }
        @include media-breakpoint-down(md) {
            padding: 0 4rem;
        }
        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }

    img {
        @extend .figure-img;
        @extend .img-fluid;
        @extend .img-thumbnail;
    }

    figcaption {
        @extend .figure-caption;
        @extend .text-right;
    }
}

body .slbOverlay {
    opacity: 0.9;
    @include animation("lightboxOverlay 1s");
}

@include keyframes("lightboxOverlay") {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.9;
    }
}
