/*
 * Basic
 */
html {
    background: $gray-800;

    body > .container,
    body > .container-fluid {
        background-color: $white;
    }
}

body {
    font-family: "Open Sans", sans-serif;
}

p {
    color: $gray-700;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    color: $gray-800;
    font-family: "Montserrat";
    font-weight: 600;
}

h1 strong {
    font-weight: 800;
}

a {
    &:hover,
    &:focus {
        color: $success !important;
    }
    &.btn:hover,
    &.btn:focus {
        color: white !important;
    }
}

.h2-b {
    font-weight: 600;
    font-family: "Montserrat";
}

.btn {
    &:focus,
    &:hover,
    &:active,
    &:active:hover,
    &:active:focus {
        box-shadow: 0 5px 15px 0 rgba($gray-800, 0.15) !important;
    }
}

.bs-default {
    box-shadow: 0 5px 15px 0 rgba($gray-800, 0.15);
}

/*
 * Header | Navi
 */
#main-navigation {
    background: transparent;
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 0;

    .navbar-nav {
        > li {
            margin-right: 1rem;
        }
    }

    .navbar-nav .nav-item {
        a {
            font-weight: 600;
            color: $gray-800;

            &:hover,
            &:focus {
                opacity: 1;
            }
        }
    }

    .btn-success {
        padding: 0.375rem 1.5rem;
    }

    .dropdown-menu {
        border: 0;

        a {
            &:hover,
            &:focus {
                background: transparent;
            }

            &:active {
                background: transparent;
            }
        }
    }
}

.navbar-dark {
    background: $gray-700;

    &,
    a,
    .navbar-nav .nav-link {
        color: #fff;
    }

    .item {
        padding: 1.5rem 1rem;
    }

    .active {
        text-decoration: underline;
    }
}

#slider-top {
    position: relative;

    .slider-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($gray-300, 0.8);
    }

    .slider-caption {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(50% + 45px);
        transform: translateY(-50%);
    }

    .btn-success {
        font-size: 1.125rem;
        padding: 0.375rem 3rem;
    }
}

/*
 * Content
 */
.subline {
    color: $gray-600;
    font-weight: 600;
    font-size: 1.125rem;
    font-family: "Open Sans", sans-serif;
}

.selection-tools {
    i {
        font-size: 2rem;
        margin-top: 3px;
        color: $primary;
    }
}

.selection-tools .media > img {
    width: 32px;
    height: auto;
}

section {
    h2.text-center {
        font-weight: 700 !important;
    }
}

.section-steps {
    .card {
        min-height: 12rem;
        border-radius: 0.5rem;
        border: 1px solid $gray-100;
        text-align: center;
        box-shadow: 0 5px 15px 0 rgba($gray-800, 0.15);
        position: relative;
    }
}

.section-testimonials {
    .testimonial-customer {
        height: 175px;
        background-size: cover !important;
        width: 175px;
        border-radius: 100%;
        box-shadow: 0 5px 15px rgba($gray-800, 0.15);
    }
}

.section-slider {
    padding: 5rem 0 !important;
    background: $gray-100;

    .carousel-indicators {
        margin-bottom: 0;
        bottom: -45px;

        li {
            background: $gray-800;
            width: 65px;
            height: 1px;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;

            &.active {
                background: $success;
            }
        }
    }
}

.section-calculation {
    background: $gray-100;

    .table-responsive {
    }

    table {
        width: 75%;
        margin: 0 auto;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 5px 15px rgba($gray-800, 0.15);

        p {
            margin-bottom: 0;
        }

        tr {
            td {
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
            }

            td:last-of-type {
                text-align: right;
                padding-right: 2.5rem;
            }
        }

        tr:first-of-type {
            td {
                border-top: 0;
                padding-top: 2.5rem;
            }
        }

        tr {
            td:first-of-type {
                padding-left: 2.5rem;
            }
        }

        tfoot {
            border-radius: 10px;

            tr {
                td {
                    padding-top: 1.5rem !important;
                    background: $primary;

                    p {
                        color: #fff;
                    }
                }

                td:first-of-type {
                    border-radius: 10px 0 0 10px;
                }

                td:last-of-type {
                    padding-top: 1.5rem !important;
                    padding-bottom: 1.5rem !important;
                    border-radius: 0 10px 10px 0;
                }
            }
        }
    }

    .card {
        border-radius: 10px;
        border: 0;

        .card-body {
            padding: 2.5rem 2rem 0.75rem 2rem;

            p {
                margin-bottom: 0;
            }

            .row {
                > div[class*="col-"]:nth-of-type(2) {
                    text-align: left;
                }
            }

            > .row:first-of-type {
                padding-bottom: 1.25rem;
                padding-top: 0;
                border: 0;
            }

            > .row {
                padding: 1.25rem 0;
                border-top: 1px solid $gray-200;
            }

            > .row:last-of-type {
                padding-top: 1.25rem;
                padding-bottom: 1.25rem;
            }
        }

        .row {
            div[class*="col-"]:last-of-type {
                text-align: right;
            }
        }

        .card-footer {
            border-radius: 10px;
            background: #376fc0;
            padding: 1.25rem 2rem;

            p {
                margin-bottom: 0;
                color: $gray-400;

                strong {
                    color: #fff;
                }
            }
        }
    }
}

.section-price {
    .card {
        border-radius: 10px;
        border: 2px solid $gray-200;

        &:hover,
        &:focus {
            border-color: $success;
        }

        &:hover .card-body .fa-circle,
        &:focus .card-body .fa-circle {
            color: $success;
        }

        &:hover .btn-secondary,
        &:focus .btn-secondary {
            background: $success;
        }

        .card-body {
            padding-bottom: 0.5rem;
            position: relative;

            .card-title {
                font-size: 1.25rem;
                margin-bottom: 0 !important;
            }

            .btn-secondary {
                border: 0;
                color: #fff;

                &:hover,
                &:focus,
                &:active {
                    background: $success !important;
                    color: #fff !important;
                    border: 0;
                }
            }

            small {
                line-height: 18px;
                display: block;
                font-size: 0.875rem;
                font-weight: 400;
            }

            .btn-lg {
                font-size: 1.075rem;
            }

            .btn-block + .btn-block {
                margin-top: 0;
            }

            .price {
                display: block;
                font-size: 1.75rem;
                text-align: center;

                > .old-price small {
                    text-decoration: line-through;

                    > sup {
                        text-decoration: none;
                    }
                }
            }
        }

        &:hover .btn-secondary,
        &:focus .btn-secondary {
            &:hover,
            &:focus {
                background: darken($success, 15%) !important;
            }
        }

        &.highlight {
            border-color: $success;

            > .card-body .fa-stack .fa-circle {
                color: $success;
            }

            .btn-secondary {
                background: $success;

                &:hover,
                &:focus {
                    background: darken($success, 15%) !important;
                }
            }
        }

        .btn.btn-link {
            &:hover,
            &:focus,
            &:active {
                box-shadow: none !important;
            }
        }
    }

    .list-features {
        padding-left: 1.5rem;
        position: relative;
    }

    li {
        font-size: 0.9rem;

        &:before {
            content: "\f00c";
            font-family: "Font Awesome 5 Pro";
            position: absolute;
            left: 0;
            font-weight: 400;
            color: $success;
        }

        &:not(:first-of-type) {
            padding-top: 0.5rem;
        }
    }

    .fa-stack {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);

        .fa-circle {
            color: $gray-600;

            + i[class*="fa-"] {
                color: #fff;
            }
        }
    }

    .btn.btn-link {
        &:hover,
        &:focus {
            box-shadow: none;
            text-decoration: none;
        }
    }
}

.section-fullwidth-cta {
    background: $success;

    p {
        color: #fff;
    }

    h2 {
        color: #fff;
    }
}

.cta-box {
    padding: 2rem !important;
    background: $success;
    border-radius: 0.75rem;
    background-image: url("#{$asset-directory}/images/cta-bg.png");
    background-position: left;
    background-repeat: no-repeat;

    @media (max-width: 575.98px) {
        border-radius: 0 !important;
    }

    > *,
    .rich-text * {
        color: #fff;
    }

    &:first-child {
        padding-top: 0;
        margin-top: 0;
    }

    a {
        margin-bottom: 1rem !important;
    }
}

.btn-cta {
    padding: 0.375rem 2.5rem;
    background: $gray-100;
    border-color: $gray-100;
    color: $gray-800 !important;
    font-weight: 600;
    font-size: 1.125rem;
}

.cta-b {
    font-family: "Montserrat";
    font-weight: 600;
}

/*
 * Footer
 */
footer {
    background: $gray-800;

    h3 {
        color: #fff;
        font-size: 1rem;
        font-weight: 600;

        + ul {
            li a {
                color: $gray-600;

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
}

/*
 * MISC
 */
.info-text {
    font-weight: 600;

    a {
        font-weight: 400;
        text-decoration: underline;
        color: $gray-800;
    }

    &.bulb {
        position: relative;

        &:before {
            position: relative;
            left: -10px;
            font-size: 1.25rem;
            content: "\f671";
            font-family: "Font Awesome 5 Pro";
            font-weight: 300;
            color: $gray-500;
        }
    }
}

.bg-whiped {
    position: relative;
    display: block;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        background: url("#{$asset-directory}/images/svg/text-bg.svg");
        display: inline-block;
        width: 200px;
        height: 50px;
        background-position: center bottom;
        background-repeat: no-repeat;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

.bg-whiped-sm {
    display: inline-block;
    position: relative;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        background: url("#{$asset-directory}/images/svg/text-bg-inline.svg");
        display: inline-block;
        width: 100%;
        height: 24px;
        background-position: inherit;
        background-repeat: no-repeat;
        background-size: cover;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    #slider-top {
        height: 450px !important;
    }
    .small-container {
        max-width: 90%;
        margin: 0 auto;
    }
    .selection-tools {
        .media {
        }

        p {
            margin-bottom: 0;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .section-calculation {
        .table-responsive {
            overflow: visible;
        }
    }
    .section-steps {
        .col-md-4:not(:last-of-type) {
            .card {
                &:after {
                    content: "\f105";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    position: absolute;
                    right: -30px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 4.5rem;
                    color: $gray-400;
                }
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
    #slider-top {
        height: 500px;

        .slider-caption h1 {
            font-size: 2rem;

            strong {
                display: block;
                margin-top: 0.5rem;
            }
        }
    }
    .table-responsive {
        overflow: hidden;
    }
    #main-navigation {
        padding: 0 15px;
    }
    .selection-tools {
        .media {
            .media-body {
                order: 1;
                text-align: left !important;
            }

            i {
                order: 0;
                margin-left: 0 !important;
                margin-right: 1rem !important;
            }
        }
    }
    .section-testimonials {
        .media {
            flex-wrap: wrap;

            .media-body {
                order: 1;
                flex-basis: 100%;
                margin-top: 1rem;
            }

            .testimonial-customer {
                height: 130px;
                width: 130px;
                float: left;
                order: 0;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 0;
            }
        }

        .bg-img-center {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
    .section-calculation {
        .card {
            .row {
                .col-12 + .col-6 {
                    text-align: left !important;
                }

                .col-12 ~ .col-6 {
                    margin-top: 0.75rem;
                }
            }

            .card-footer {
                p + p {
                    margin-top: 0.25rem;
                }
            }
        }
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar-collapse {
        width: 100%;
        padding: 2rem;
        background: #fff;
        box-shadow: 0 5px 15px 0 rgba($gray-800, 0.15);

        a {
            font-size: 1.125rem;
        }
    }
    .section-fullwidth-cta {
        text-align: center !important;

        .btn-cta {
            margin-top: 1rem;
        }
    }

    footer .row h3 {
        margin-top: 1rem;
    }

    .mobile-signup .btn-success {
        margin-right: 0.5rem;
        margin-top: -3px !important;
    }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    .section-price .card {
        flex: 1 0 calc(50% - 30px);
    }
}

/*
 * Overwrites
 */
.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.btn-success {
    &:hover,
    &:focus {
        color: #fff !important;
    }
}

/*
 * Fonts
 */
/* open-sans-regular - latin */
@font-face {
    font-family: "Open Sans";
    font-display: optional;
    font-style: normal;
    font-weight: 400;
    src: url("#{$font-directory}/fonts/open-sans-v15-latin-regular.eot"); /* IE9 Compat Modes */
    src:
        local("Open Sans Regular"),
        local("OpenSans-Regular"),
        url("#{$font-directory}/fonts/open-sans-v15-latin-regular.eot")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("#{$font-directory}/fonts/open-sans-v15-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("#{$font-directory}/fonts/open-sans-v15-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("#{$font-directory}/fonts/open-sans-v15-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("#{$font-directory}/fonts/open-sans-v15-latin-regular.svg#OpenSans")
            format("svg"); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
    font-family: "Open Sans";
    font-display: optional;
    font-style: normal;
    font-weight: 600;
    src: url("#{$font-directory}/fonts/open-sans-v15-latin-600.eot"); /* IE9 Compat Modes */
    src:
        local("Open Sans SemiBold"),
        local("OpenSans-SemiBold"),
        url("#{$font-directory}/fonts/open-sans-v15-latin-600.eot")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("#{$font-directory}/fonts/open-sans-v15-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("#{$font-directory}/fonts/open-sans-v15-latin-600.woff")
            format("woff"),
        /* Modern Browsers */
            url("#{$font-directory}/fonts/open-sans-v15-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("#{$font-directory}/fonts/open-sans-v15-latin-600.svg#OpenSans")
            format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
    font-family: "Montserrat";
    font-display: fallback;
    font-style: normal;
    font-weight: 400;
    src: url("#{$font-directory}/fonts/montserrat-v12-latin-regular.eot"); /* IE9 Compat Modes */
    src:
        local("Montserrat Regular"),
        local("Montserrat-Regular"),
        url("#{$font-directory}/fonts/montserrat-v12-latin-regular.eot")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("#{$font-directory}/fonts/montserrat-v12-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-regular.woff")
            format("woff"),
        /* Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("#{$font-directory}/fonts/montserrat-v12-latin-regular.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}

/* montserrat-600 - latin */
@font-face {
    font-family: "Montserrat";
    font-display: fallback;
    font-style: normal;
    font-weight: 600;
    src: url("#{$font-directory}/fonts/montserrat-v12-latin-600.eot"); /* IE9 Compat Modes */
    src:
        local("Montserrat SemiBold"),
        local("Montserrat-SemiBold"),
        url("#{$font-directory}/fonts/montserrat-v12-latin-600.eot")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("#{$font-directory}/fonts/montserrat-v12-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-600.woff")
            format("woff"),
        /* Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("#{$font-directory}/fonts/montserrat-v12-latin-600.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}

/* montserrat-700 - latin */
@font-face {
    font-family: "Montserrat";
    font-display: fallback;
    font-style: normal;
    font-weight: 700;
    src: url("#{$font-directory}/fonts/montserrat-v12-latin-700.eot"); /* IE9 Compat Modes */
    src:
        local("Montserrat Bold"),
        local("Montserrat-Bold"),
        url("#{$font-directory}/fonts/montserrat-v12-latin-700.eot")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("#{$font-directory}/fonts/montserrat-v12-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-700.woff")
            format("woff"),
        /* Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("#{$font-directory}/fonts/montserrat-v12-latin-700.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}

/* montserrat-900 - latin */
@font-face {
    font-family: "Montserrat";
    font-display: fallback;
    font-style: normal;
    font-weight: 900;
    src: url("#{$font-directory}/fonts/montserrat-v12-latin-900.eot"); /* IE9 Compat Modes */
    src:
        local("Montserrat Black"),
        local("Montserrat-Black"),
        url("#{$font-directory}/fonts/montserrat-v12-latin-900.eot")
            format("embedded-opentype"),
        /* IE6-IE8 */
            url("#{$font-directory}/fonts/montserrat-v12-latin-900.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-900.woff")
            format("woff"),
        /* Modern Browsers */
            url("#{$font-directory}/fonts/montserrat-v12-latin-900.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("#{$font-directory}/fonts/montserrat-v12-latin-900.svg#Montserrat")
            format("svg"); /* Legacy iOS */
}

@font-face {
    font-family: "Font Awesome 5 Pro";
    font-display: block;
    font-style: normal;
    font-weight: 900;
    src: url("#{$font-directory}/fonts/fa-solid-900.eot");
    src:
        url("#{$font-directory}/fonts/fa-solid-900.eot")
            format("embedded-opentype"),
        url("#{$font-directory}/fonts/fa-solid-900.woff2") format("woff2"),
        url("#{$font-directory}/fonts/fa-solid-900.woff") format("woff"),
        url("#{$font-directory}/fonts/fa-solid-900.ttf") format("truetype"),
        url("#{$font-directory}/fonts/fa-solid-900.svg") format("svg");
}

.fa,
.fas {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
}

@font-face {
    font-family: "Font Awesome 5 Pro";
    font-display: block;
    font-style: normal;
    font-weight: 400;
    src: url("#{$font-directory}/fonts/fa-regular-400.eot");
    src:
        url("#{$font-directory}/fonts/fa-regular-400.eot")
            format("embedded-opentype"),
        url("#{$font-directory}/fonts/fa-regular-400.woff2") format("woff2"),
        url("#{$font-directory}/fonts/fa-regular-400.woff") format("woff"),
        url("#{$font-directory}/fonts/fa-regular-400.ttf") format("truetype"),
        url("#{$font-directory}/fonts/fa-regular-400.svg") format("svg");
}

.far {
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
}

@font-face {
    font-family: "Font Awesome 5 Pro";
    font-display: block;
    font-style: normal;
    font-weight: 300;
    src: url("#{$font-directory}/fonts/fa-light-300.eot");
    src:
        url("#{$font-directory}/fonts/fa-light-300.eot")
            format("embedded-opentype"),
        url("#{$font-directory}/fonts/fa-light-300.woff2") format("woff2"),
        url("#{$font-directory}/fonts/fa-light-300.woff") format("woff"),
        url("#{$font-directory}/fonts/fa-light-300.ttf") format("truetype"),
        url("#{$font-directory}/fonts/fa-light-300.svg") format("svg");
}

.fal {
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
}

/**
 * Application styles
 */

* {
    word-break: break-word;
    word-wrap: normal;
}

img {
    max-width: 100%;
    height: auto;
}

.text-strike {
    text-decoration: line-through;
}

.text-blurred {
    color: transparent;
    text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
    text-transform: capitalize;
}

#slider-top-app {
    height: 180px;
    background-size: cover;
    background-position: center 35%;
}

.rich-text {
    @extend .clearfix;
}

.layout-home {
    h1 {
        padding-top: 4rem;
    }
}

.content > .container {
    padding-bottom: 8rem;
}

h1 {
    text-align: center;
}

h1,
h2,
h3 {
    small {
        @extend .subline;
    }
}

.simple-text-block,
.advanced-text-block,
.testimonial-block,
.price-table-block {
    margin-top: 4rem;
    margin-bottom: 8rem;
}

.simple-text-block,
.advanced-text-block {
    padding: 0 12rem;
}

.content-text-block {
    clear: both;

    h1,
    h2,
    h3 {
        text-align: center;
    }
}

.rich-text {
    clear: both;

    h2 {
        margin-top: 4rem;
        margin-bottom: 2rem;
    }
}
.content > .container-fluid > h1,
.content > .container-fluid > .content-text-block,
.multi-content-page > .container-fluid .content .content-text-block,
.content > .container-fluid > .quote-block,
.content > .container-fluid > .analytics-tracking-switch,
.content > .container-fluid > .cr_form,
.content > .container-fluid > .analytics-tracking-switch,
.content > .container-fluid > .image-block,
.content > .container-fluid > .rich-text {
    @extend .container;
}

.quote-block {
    &.quote-hero {
        font-size: 2rem;
        font-style: italic;

        p {
            @extend .col-12;
            padding: 8rem 6rem 4rem 6rem;
            @include media-breakpoint-down("md") {
                padding: 4rem 2rem 0 4rem;
            }
            @include media-breakpoint-down("sm") {
                padding: 2rem 0 0 2rem;
            }
        }
    }
}

textarea.form-control {
    height: 4rem;
}

.form-actions {
    padding: $grid-gutter-width 0;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
}

.btn-primary {
    color: $white;

    &:hover,
    &:focus {
        $hover-background: darken($primary, 7.5%);
        color: color-yiq($hover-background) !important;
    }
}

.btn-secondary {
    color: $white;

    &:hover,
    &:focus {
        $hover-background: darken($secondary, 7.5%);
        color: color-yiq($hover-background) !important;
    }
}

.modal-open > .container-fluid {
    filter: blur(1rem);
}

.modal-dialog {
    max-width: $modal-lg;
}

.modal-header {
    .modal-title {
        order: 1;
    }

    .close {
        order: 2;
    }
}

.intro-text {
    margin-bottom: 3rem;
    padding-top: 5rem;
    padding-bottom: 3rem;
    background: $gray-100;
    font-size: 1.25rem;
    line-height: 2rem;
    text-align: center;

    p {
        margin-bottom: 2rem;
    }
}

.section-hint {
    .rich-text,
    p {
        display: inline;
    }

    a {
        text-decoration: none;
        color: $green;
        font-weight: inherit;
    }
}

#genericModal {
    form {
        .form-group {
            > label {
                display: none;
            }
        }
    }
}

.dashboard-blocks {
    @extend .card-deck;
    padding: $grid-gutter-width;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 0;

        .dashboard-block {
            margin-bottom: 2rem;
        }
    }
}

.dashboard-block {
    @extend .card;

    .heading {
        word-spacing: 100vw;
    }

    .btn {
        margin-top: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.dashboard-block-content {
    @extend .card-body;
}

.account-card {
    @extend .card;
    margin-bottom: $grid-gutter-width;
    padding: $grid-gutter-width;
}

.competence-order-list {
    //@extend .list-group;

    .competence {
        //@extend .list-group-item;
        @extend .row;
        margin-bottom: 0.25rem;
        font-size: 1.25rem;

        &.inactive {
            color: $gray-600;
        }

        &:hover {
            cursor: grab;
        }
    }

    .counter {
        @extend .col-1;
    }

    .name {
        @extend .col-11;
        padding-left: 0;
    }

    .description {
        border-top: 1px solid $primary;
        @extend .offset-1;
        @extend .col-11;
    }

    ul {
        padding-top: 0.5rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }

    li {
        font-size: 1rem;
        padding-bottom: 0.125rem;
    }

    .sortable-placeholder {
        height: 2rem;
        margin-bottom: 0.25rem;
        border: 1px solid $primary;
    }

    .collapse-info-icon {
        float: right;
        color: $blue;
        background-color: $white;
        border-color: $primary;
        border-style: solid;
        border-radius: 2rem;
        border-width: 1px;
        display: block;
        width: 2rem;
        height: 2rem;
        line-height: 1.75rem;
        text-align: center;
        cursor: pointer;

        &.collapsed {
            color: $white;
            background-color: $primary;
        }
    }

    .fira-icon {
        @extend .fa;
    }

    .fira-info {
        @extend .fa-info;
    }
}

.price-packages {
    @extend .card-deck;

    .price-package {
        @extend .card;
        border-radius: 10px;
        border: 2px solid $gray-200;

        &.highlight,
        &:hover,
        &:focus {
            border-color: $success;
        }

        &.highlight,
        &:hover,
        &:focus {
            .btn {
                @extend .btn-success;
            }
        }
    }

    .price-package-header {
        @extend .card-header;
        font-size: 1.75rem;
        text-align: center;
    }

    .price-package-content {
        @extend .card-body;
        display: grid;
        grid-template-rows: 1fr 3fr auto;
    }

    .discount-name,
    .value {
        display: none;
    }

    form {
        @extend .card-footer;
    }

    .heading {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .btn {
        @extend .btn-secondary;
        @extend .btn-block;
    }
}

#confirm-checkout {
    @extend .row;

    p {
        display: none;
    }

    .btn {
        @extend .col-6;
    }
}

.spoiler,
.contact-spoiler-block {
    margin: 4rem 0;
    padding: 2rem 2rem 2rem 8rem;
    background: $success;
    color: $white;
    border-radius: 0.75rem;
    background-image: url("#{$asset-directory}/images/cta-bg.png");
    background-position: left;
    background-repeat: no-repeat;
    font-size: 1.25rem;

    &.spoiler-blue {
        background-color: $blue;
    }

    &.fullsize {
        border-radius: 0;
        margin: 4rem 0 0 0;
        padding: 4rem 0 4rem 0;
    }

    @include media-breakpoint-down(md) {
        border-radius: 0 !important;
        padding: 2rem;
    }

    *,
    a:hover,
    a:focus {
        color: #ffffff !important;
    }

    img {
        margin-bottom: 2rem;
    }

    h1,
    h2,
    h3,
    .headline {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .headline {
        font-size: 1.5rem;
        font-weight: bold;
    }

    a {
        font-style: italic;
    }

    .btn {
        border-color: darken($success, 10%);
        background-color: #ffffff;
        color: darken($success, 5%) !important;

        &:hover,
        &:focus {
            background-color: #fafafa;
            color: darken($success, 7%) !important;
        }
    }

    &.spoiler-blue .btn {
        border-color: darken($blue, 10%);
        background-color: #ffffff;
        color: darken($blue, 5%) !important;

        &:hover,
        &:focus {
            background-color: #fafafa;
            color: darken($blue, 7%) !important;
        }
    }
}

.spoiler {
    font-family: "Montserrat";
    font-weight: 600;
}

.testimonial-block {
    img {
        @extend .img-fluid;
        @extend .rounded-circle;
        @extend .img-thumbnail;
    }
}

.testimonial {
    margin-bottom: 2rem;
}

blockquote {
    @extend .row;
    p {
        @extend .col-8;
        padding: 4rem 2rem 0 12rem;
        @include media-breakpoint-down("md") {
            padding: 4rem 2rem 0 4rem;
        }
        @include media-breakpoint-down("sm") {
            padding: 2rem 0 0 2rem;
        }
    }
    img {
        @extend .col-2;
    }
    footer {
        @extend .col-10;
        background: none;
        text-align: right;
    }
}

.richtext-image {
    &.right {
        margin: 0 0 2rem 2rem;
        float: right;
    }

    &.image-w20 {
        width: 20%;
    }
}
img.lightbox:hover {
    cursor: zoom-in;
}

@import "seminars";
@import "blog";
@import "faq";
@import "image_block";
@import "forms";

video {
    width: 100%;
    height: auto;
}

iframe {
    width: 1110px !important;
    max-width: 100% !important;
    height: 624px !important;

    @include media-breakpoint-down("lg") {
        width: 930px !important;
        height: 523px !important;
    }

    @include media-breakpoint-down("md") {
        width: 690px !important;
        height: 388px !important;
    }

    @include media-breakpoint-down("sm") {
        width: 510px !important;
        height: 287px !important;
    }
}

.flat-menu {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;

    a,
    a.ancestor,
    a.active {
        text-decoration: none;
    }

    > .item {
        flex-grow: 1;
        flex-basis: 0;

        text-align: center;
        white-space: nowrap;

        > a {
            &:hover,
            &:active,
            &:focus {
                color: $white !important;
                outline: none;
            }
        }

        &.active,
        &.ancestor {
            background: $gray-600;
        }
    }

    .dropdown-menu {
        background: $gray-700;
    }

    .dropdown-item.active:hover,
    .dropdown-item.active:focus,
    .dropdown-item.active:active,
    .dropdown-item.ancestor:focus,
    .dropdown-item.ancestor:active,
    .dropdown-item.ancestor:hover {
        color: $white !important;
    }
}
