$blue: #3770c0;
$green: #3ec05c;
$red: #d9272e;
$gray-700: #5d6165;
$body-color: $gray-700;
$gray-600: #999da1;
$gray-800: #212529;
$gray-300: #eeeeee;
$gray-100: #fafafa;
$gray-400: #dddddd;
$success: $green;
$link-color: $success;
$enable-rounded: false;
$enable-transitions: false;
$btn-success: $green;
$font-directory: "../" !default;
$asset-directory: "../" !default;
$input-btn-focus-width: 0;

/* Font size */
$h1-font-size: 2.25rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
