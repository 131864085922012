.webinar-intro {
    padding: 2rem 0 2rem 0;
}

.webinars-list {
    h2 {
        padding: 0 0 0.5rem 0;
        small {
            color: $gray-600;
            display: block;
            white-space: nowrap;
            font-style: italic;
        }
    }

    .webinar {
        padding: 2rem;
        margin-bottom: 3rem;
        @include media-breakpoint-down("md") {
            padding: 1rem;
        }

        border: 2px solid $gray-300;
        &:hover {
            border: 2px solid $success;
        }
    }
}

.webinar-details {
    margin: 0 6rem;
    @include media-breakpoint-down("md") {
        margin: 0;
    }

    h1 {
        padding: 0;
        margin-bottom: 3rem;
        small {
            color: $gray-700;
            display: block;
            white-space: nowrap;
            font-size: $h1-font-size * 0.5;
            text-align: right;
        }
    }

    h2 {
        margin-top: 2rem;
    }

    .webinar p {
        margin-bottom: 1rem;
    }
}

.webinar-registration {
    margin: 0 6rem;
}

.webinar {
    padding: 2rem;
    @include media-breakpoint-down("md") {
        padding: 1rem;
    }

    p {
        font-size: 1rem;
    }
}

.webinar-events-list {
    margin-bottom: 1rem;
}
