.background-video {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
    }

    .static {
        display: none;
        background-size: cover;
        background-position: center;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }

    .overlay {
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 15;
    }
}

#slider-top .slider-caption {
    z-index: 20;
}

@media (max-width: 767px) {
    .background-video video {
        display: none;
    }
    .background-video .static {
        display: block;
    }
}

#slider-top.teaser-video {
    height: 540px !important;
}
