.blog-intro {
    @extend .lead;
    padding: 2rem 0 2rem 0;
}

.blog-post {
    margin-bottom: 2rem;
}

.blog-posts {
    .blog-post {
        padding: 2rem;
        border: 2px solid $gray-300;

        &:hover {
            border: 2px solid $success;
        }
    }
}

.blog-post-details {
    margin: 0 6rem;

    h1 {
        padding: 0;
    }

    h2 {
        margin-top: 2rem;
    }

    .post-meta {
        margin-bottom: 2rem;
        text-align: right;
    }
}

.post-meta {
    font-style: italic;
    color: $gray-600;
}
